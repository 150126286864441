const setSearchParam = (name, value) => {
  const url = new URL(window.location.href)
  const { searchParams } = url
  searchParams.set(name, encodeURIComponent(value))
  url.search = searchParams.toString()
  return url
}

const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search)
  return decodeURIComponent(urlParams.get(param) || '')
}

const getReferenceUrl = () => {
  const queryParam = getQueryParam('utm-source')
  if (!!queryParam) {
    return queryParam
  }

  return dataLayer?.find(x => x.referringURL)?.referringURL ?? ''
}

const getPageUrl = () => {
  const url = document.querySelector('meta[property="og:url"]').content
  return url ? `${url}${location.search}` : location.href
}

// eslint-disable-next-line import/prefer-default-export
export { getQueryParam, setSearchParam, getPageUrl, getReferenceUrl }
